import React from 'react';
import '../../../assets/css/zero-to-one.css';

function Header() {
  return (
    <main className="main">
      <div className="container-fluid">
        <div className="main-padding heading-container-padding row d-flex justify-content-center align-items-center">
          <div className="col-12 col-md-10 col-lg-12 content-slide-top-container padding-top-heading">
            <div className="heading-title-white">Helping startups go from Zero to One</div>
            <div className="description latoRegular">
              <p className="heading-description-white">
                With over 160 startups and 10 unicorns supported, Tintash is the best choice for
                pre-Series B companies seeking high-value, offshore expertise
                <br />
                <br />
                We combine co-located fractional Tech/Product{' '}
                <a
                  style={{color: 'white', textDecoration: 'underline'}}
                  href="https://cptofracs.com/members"
                  target="_blank"
                >
                  leadership
                </a>{' '}
                with a global team of skilled developers and designers, leveraging AI for rapid
                iterations to iterate towards product-market fit.
              </p>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}

export default Header;
