import React from 'react';
import '../../../assets/css/zero-to-one.css';
import deisgnpro from '../../../assets/images/zero-to-one/design-pro.png';
import theplayerslounge from '../../../assets/images/zero-to-one/player-lounge.png';
import omniactivesport from '../../../assets/images/zero-to-one/omni-active.png';
import lifeprint from '../../../assets/images/zero-to-one/lifeprint.png';
import decorist from '../../../assets/images/zero-to-one/decorist.png';
import briallantly from '../../../assets/images/zero-to-one/brilliantly.png';
import embr from '../../../assets/images/zero-to-one/embr.png';
import raven from '../../../assets/images/zero-to-one/raven.png';

function FeaturedClient() {
  return (
    <div className="featured-client d-flex flex-column">
      <div className="featured-client-header d-flex flex-column">
        <h2 className="font-weight-bold font-size-xl header" style={{color: '#4E4B66'}}>
          Our Featured Clients
        </h2>
        <p className="lead">
          We have a strong focus on HealthTech and Emerging Tech.
        </p>
        <div className="logos-container">
          <div className="row justify-content-center align-items-center">
            <div className="col-6 col-md-3 mb-4 d-flex justify-content-center">
              <img
                src={deisgnpro}
                alt="DesignPro"
                className="img-fluid"
                style={{transform: 'scale(1.4)'}}
              />
            </div>
            <div className="col-6 col-md-3 mb-4 d-flex justify-content-center">
              <img src={theplayerslounge} alt="The Players' Lounge" className="img-fluid" />
            </div>
            <div className="col-6 col-md-3 mb-4 d-flex justify-content-center">
              <img
                src={omniactivesport}
                alt="OMNI-ACTIVE FITNESS"
                className="img-fluid"
                style={{transform: 'scale(1.3)'}}
              />
            </div>
            <div className="col-6 col-md-3 mb-4 d-flex justify-content-center">
              <img src={lifeprint} alt="Lifeprint" className="img-fluid" />
            </div>
            <div className="col-6 col-md-3 mb-4 d-flex justify-content-center">
              <img
                src={decorist}
                alt="decorist"
                className="img-fluid"
                style={{transform: 'scale(1.2)'}}
              />
            </div>
            <div className="col-6 col-md-3 mb-4 d-flex justify-content-center">
              <img
                src={embr}
                alt="Embr Wave"
                className="img-fluid"
                style={{transform: 'scale(1.2)'}}
              />
            </div>
            <div className="col-6 col-md-3 mb-4 d-flex justify-content-center">
              <img src={briallantly} alt="brilliantly" className="img-fluid" />
            </div>
            <div className="col-6 col-md-3 mb-4 d-flex justify-content-center">
              <img src={raven} alt="Raven" className="img-fluid" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FeaturedClient;
