import React from 'react';

import Layout from '../components/Shared/Layout';
import ZeroToOne from '../components/Connect/Zero-to-One/ZeroToOne';

export default () => (
  <Layout isHomepage>
    <ZeroToOne />
  </Layout>
);
