import React from 'react';
import '../../../assets/css/zero-to-one.css';
import {navigate} from 'gatsby';
import designProBanner from '../../../assets/images/zero-to-one/design-pro-banner.webp';
import playerLoungeBanner from '../../../assets/images/zero-to-one/player-lounge-banner.webp';
import deisgnpro from '../../../assets/images/zero-to-one/design-pro.png';
import theplayerslounge from '../../../assets/images/zero-to-one/player-lounge.png';
import thecommercebanner from '../../../assets/images/zero-to-one/commerce-platform-cover.webp';
import allergyamuletbanner from '../../../assets/images/zero-to-one/allergy-amulet.webp';

function FeaturedPortfolios() {
  return (
    <div className="featured-portfolios d-flex flex-column" id="casestudies">
      <div className="featured-portfolios-header d-flex flex-column mb-4">
        <h2 className="font-weight-bold font-size-xl" style={{color: '#4E4B66'}}>
          Case Studies
        </h2>
      </div>

      <div
        className="featured-portfolios-item bg-white rounded shadow-lg overflow-hidden mb-4 mb-lg-5"
        style={{
          backgroundImage: `url(${designProBanner})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
        }}
      >
        <div className="linear-gr">
          <div className="d-flex flex-column col-lg-6 case-study-wrapper">
            <img src={deisgnpro} alt="DesignPro" className="img-fluid mb-2 mb-lg-4" />
            <p className="text-dark mb-2 mb-lg-4">
              Using AI to help designers, product managers, and marketers simplify task management
              for faster, better design.
            </p>

            <p className="text-dark mb-3 mb-lg-5">
              We developed the <span className="bold-text">AI solutions</span> to{' '}
              <span className="bold-text">automate design reviews, generate feedback</span>, and{' '}
              <span className="bold-text">convert it into structured tasks</span> using LLMs
              fine-tuned on Figma, Slack, and Google Docs data.
            </p>
            <div className="row mb-3 mb-lg-5">
              <div className="col-md-3 mb-3 mb-md-0 border-md-right">
                <h4 className="h3 extra-bold-custom">1+</h4>
                <p className="small text-dark">Rounds of Pre-seed funding</p>
              </div>
              <div className="col-md-3 mb-3 mb-md-0 border-md-right">
                <h4 className="h3 extra-bold-custom">1000+</h4>
                <p className="small text-dark">Organic users within 7 days of launch.</p>
              </div>
              <div className="col-md-3 mb-3 mb-md-0 border-md-right">
                <h4 className="h3 extra-bold-custom">3600</h4>
                <p className="small text-dark">Total users and 40+ DAU (Daily Active Users)</p>
              </div>
              <div className="col-md-3 border-md-right">
                <h4 className="h3 extra-bold-custom">Top 20</h4>
                <p className="small text-dark">Figma plugins list</p>
              </div>
            </div>
            <button
              type="button"
              className="btn custom-button mb-3 mb-lg-0"
              onClick={() => {
                navigate('/portfolio/design-pro/');
              }}
            >
              View Case Study →
            </button>
          </div>

          <div className="col-lg-6 w-100 d-none d-lg-block" />
        </div>
      </div>

      <div
        className="featured-portfolios-item bg-white rounded shadow-lg overflow-hidden mb-4 mb-lg-5"
        style={{
          backgroundImage: `url(${allergyamuletbanner})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
        }}
      >
        <div className="linear-gr">
          <div className="d-flex flex-column col-lg-6 case-study-wrapper">
            <h4 className="h3 extra-bold-custom mb-2">
              World’s Fastest Consumer Food Allergen Sensor
            </h4>
            <p className="text-dark mb-4">
              We designed a sleek and lightweight product in 4 months that could deliver test
              results for the 8 most common food allergens within 60 seconds.
            </p>

            <div className="row mb-5">
              <div className="col-md-4 mb-3 mb-md-0 border-md-right">
                <h4 className="h3 extra-bold-custom">$3.3M</h4>
                <p className="small text-dark">Raised in funding in 2020</p>
              </div>
              <div className="col-md-4 border-md-right">
                <h4 className="h3 extra-bold-custom">$0.8M</h4>
                <p className="small text-dark">Raised in Seed round in 2021</p>
              </div>
            </div>
            <button
              type="button"
              className="btn custom-button mb-3 mb-lg-0"
              onClick={() => {
                navigate('/portfolio/food-allergen-sensor/');
              }}
            >
              View Case Study →
            </button>
          </div>

          <div className="col-lg-6 w-100 d-none d-lg-block" />
        </div>
      </div>

      <div
        className="featured-portfolios-item bg-white rounded shadow-lg overflow-hidden mb-4 mb-lg-5"
        style={{
          backgroundImage: `url(${playerLoungeBanner})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
        }}
      >
        <div className="linear-gr">
          <div className="d-flex flex-column col-lg-7 case-study-wrapper">
            <img src={theplayerslounge} alt="DesignPro" className="img-fluid mb-4" />
            <p className="text-dark mb-4">Fan engagement platform for athletes & fans.</p>

            <p className="text-dark mb-5">
              Created a <span className="bold-text">fan engagement platform</span> for college
              sports, gamifying the fan experience and fostering{' '}
              <span className="bold-text">direct athlete-fan interaction.</span>
            </p>
            <div className="row mb-5">
              <div className="col-md-4 mb-3 mb-md-0 border-md-right">
                <h4 className="h3 extra-bold-custom">3 months</h4>
                <p className="small text-dark">Launched the Go-to-Market MVP in 3 months</p>
              </div>
              <div className="col-md-4 mb-3 mb-md-0 border-md-right">
                <h4 className="h3 extra-bold-custom">$2.5M</h4>
                <p className="small text-dark">in funding raised till date.</p>
              </div>
              <div className="col-md-4 border-md-right">
                <h4 className="h3 extra-bold-custom">4 Colleges</h4>
                <p className="small text-dark">signed up on the platform till date</p>
              </div>
            </div>
            <button
              type="button"
              className="btn custom-button mb-3 mb-lg-0"
              onClick={() => {
                navigate('/portfolio/thePlayerLounge/');
              }}
            >
              View Case Study →
            </button>
          </div>

          <div className="col-lg-5 w-100 d-none d-lg-block" />
        </div>
      </div>

      <div
        className="featured-portfolios-item bg-white rounded shadow-lg overflow-hidden mb-4 mb-lg-5"
        style={{
          backgroundImage: `url(${thecommercebanner})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
        }}
      >
        <div className="linear-gr">
          <div className="d-flex flex-column col-lg-6 case-study-wrapper">
            <h4 className="h3 extra-bold-custom mb-2">B2B Commerce Enablement Platform</h4>
            <p className="text-dark mb-4">
              Using AI to help designers, product managers, and marketers simplify task management
              for faster, better design.
            </p>

            <p className="text-dark mb-5">
              Developed a platform that{' '}
              <span className="bold-text">
                automates 3D product generation, online interior design,
              </span>{' '}
              and
              <span className="bold-text">photorealistic renders,</span> attracting{' '}
              <span className="bold-text">top enterprise clients.</span>
            </p>
            <div className="row mb-5">
              <div className="col-md-4 mb-3 mb-md-0 border-md-right">
                <h4 className="h3 extra-bold-custom">$1.5M ARR</h4>
                <p className="small text-dark">generated in three years</p>
              </div>
              <div className="col-md-4 border-md-right">
                <h4 className="h3 extra-bold-custom">$2.7M</h4>
                <p className="small text-dark">raised in funding over multiple rounds</p>
              </div>
            </div>
          </div>

          <div className="col-lg-6 w-100 d-none d-lg-block" />
        </div>
      </div>
    </div>
  );
}

export default FeaturedPortfolios;
