import React from 'react';
import {Helmet} from 'react-helmet';
import Header from './Header';
import FeaturedClient from './FeaturedClient';
import FeaturedPortfolios from './FeaturedPortfolios';
import How from '../../How';
import StudioNavbar from '../../Shared/StudioNavbar';

function ZeroToOne() {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Zero-to-One | Tintash</title>
      </Helmet>
      <StudioNavbar studioName="Zero-to-One Studio" />
      <Header />
      <FeaturedClient />
      <FeaturedPortfolios />
      <How />
    </>
  );
}

export default ZeroToOne;
